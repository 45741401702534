
























































import BaseCard from "@/components/Base/BaseCard.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
    },
})
export default class SharedPlanHeader extends Vue {
    @Prop() search!: string;
    @Prop() hideButton!: boolean;

    get searchValue() {
        return this.search;
    }

    set searchValue(search: string) {
        this.updateSearch(search);
    }

    @Emit("search:update")
    updateSearch(search: string) {
        return search;
    }

    @Emit("plan:create")
    createPlan() {
        return;
    }
}
